








































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Footer extends Vue {
salerQq = window.localStorage.getItem("salerQq");

}
