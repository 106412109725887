



























































































































































































































































































































import _defaultImg from '@/assets/ssms.png'
import { Component, Vue, Watch } from 'vue-property-decorator'
import {  listMateriaByPartnumber ,callReptileGetMateriaList} from "@/api";
import Footer from "@/components/Footer.vue";
import $ from 'jquery'

interface IProduct {
  // id: number
  //爬虫
  bjxh: string
  sccj: string
  partDesc: string
  kc: number
  jg: Array<string>
  showMore: boolean,
  //OA
  id:string,
  materialId:number,
  partnumber:string,
  rsp: Array<string>,
  description:string,
  mft:string,
  leadtime:string,
  qty:string,
  pc:string,
  sfty:string
}



interface IProductByFactory {
  gys: string
  logo: string
  bj: Array<IProduct>
}

@Component({
  components: {Footer},
})
export default class Search extends Vue {
  keyword = ''
  productsSummary: Array<IProduct> = []
  allProductsSummary: Array<IProduct> = []
  clickMoreCount = 2
  productsByFactory: Array<IProductByFactory> = []
  summaryLoading = true;
  factoryLoading = true;
  hxwStatus = window.localStorage.getItem("hxwStatus")
  userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
  dialogAskPriceVisible = false;
  salerQq = window.localStorage.getItem("salerQq");
  showFooter=false;
  fullscreenLoading = false;
  @Watch('$route.params.ts')
  onIdChanged(): void {
      // let jsonKey = {}
      // jsonKey['keyword'] = this.$route.query.keyword.toString().trim()
      // console.log(JSON.stringify(jsonKey))
      // console.log(encodeURIComponent(JSON.stringify(jsonKey)))
    // console.log(this.$route.query.keyword,"eeqw")
    this.keyword = encodeURIComponent(this.$route.query.keyword.toString().trim().replace(/[\u4e00-\u9fa5]/ig,'') )
    // console.log(this.keyword,"123123")
    // this.$route.params.keyword.trim();
    if(this.keyword.length <= 2){
      this.$message.warning("最少需要输入三位");
      return;
    }
    this.summaryLoading = true;
    this.factoryLoading = true;
    this.listMateriaByPartnumber();
    this.callReptileGetMateriaList();
  }

  mounted(): void {
    this.keyword =  encodeURIComponent(this.$route.query.keyword.toString().trim());
    // this.getProducts()
    this.listMateriaByPartnumber();
    this.callReptileGetMateriaList();
   

  }; 
  openFullScreen():void {
    let delaySeconds = Math.floor(Math.random() * (1500 - 600 + 1)) + 600;
    this.fullscreenLoading = true;
    setTimeout(() => {
      this.fullscreenLoading = false;
    }, delaySeconds);
  }
  showRecordMore():void{
    this.openFullScreen()
    this.productsSummary = this.allProductsSummary.slice(0,this.clickMoreCount * 50);
    // $('body,html').animate({ scrollTop: 300 * this.clickMoreCount}, 400)
    this.clickMoreCount += 1;
    

    
  }
  listMateriaByPartnumber(): void{
  
    listMateriaByPartnumber(this.keyword).then(res => {
      // console.log(res)
      this.summaryLoading = false;
      if(res.status == 200){
          if(res.data.code == 200){
            
          //  console.log(res.data.data)
           this.productsSummary = res.data.data.slice(0,50);
           this.allProductsSummary = res.data.data
           this.showFooter=true;
       

          //  this.$emit.setSearchShowFooter(true) ;
          //  console.log(this.productsSummary)
          //  for(let i = 0 ;i <res.data.data.length;i++){
          //    let item = {
          //       //爬虫
          //       bjxh: res.data.data[i].bjxh,
          //       jg: res.data.data[i].jg,
          //       sccj: res.data.data[i].sccj,
          //       partDesc: res.data.data[i].partDesc,
          //       kc: res.data.data[i].kc,
          //       // oa
          //       rsp: res.data.data[i].rsp,
          //       partnumber: res.data.data[i].partnumber,
          //       description:res.data.data[i].description,
          //       mft:res.data.data[i].mft,
          //       leadtime:res.data.data[i].leadtime,
          //       qty:res.data.data[i].qty,
          //       showMore: false
                
          //    }
          //    this.productsSummary.push(item)
          //  }
            // console.log(this.productsSummary)
          }else{
            this.$message.error(res.data.msg );
          }
        }else{
          this.$message.error(res.data.statusText);
        } 
      }).catch(() => {
        console.log(' error ')
      })
  };

  callReptileGetMateriaList(): void{
     this.productsByFactory = [];
    callReptileGetMateriaList(this.keyword).then(res => {
      // console.log(res)
      this.factoryLoading = false;
      if(res.status == 200){
          if(res.data.code == 200){
            
          //  console.log(res.data.data[0].ghxx)
            this.productsByFactory = res.data.data[0].ghxx;      
            this.showFooter=true;

          }else{
            this.$message.error(res.data.msg );
          }
        }else{
          this.$message.error(res.data.statusText);
        } 
      }).catch(() => {
        console.log(' error ')
      })
  };
  getProducts(): void {
    // for (let i = 1; i < 4; i++) {
    //   this.productsSummary.push({
    //     id: i,
    //     modal: 'AD9625BBPZ-2.0',
    //     name: 'Analog Devices Inc',
    //     description: '1-Channel Single ADC Pipelined 2Gsps 12-bit JESD204B 196-Pin TEBGA Tray',
    //     amount: 120,
    //     time: '1W',
    //     price: ['1----100', '200----100', '300----100'],
    //   })
    // }

    // for (let i = 1; i < 3; i++) {
    //   this.productsByFactory.push({
    //     id: i,
    //     image: _defaultImg,
    //     products: this.productsSummary,
    //   })
    // }
  }

  handleAskPrice(item: IProduct): void {
    // console.log(item)
    this.$message.success("询价咯")
  }
  showMore(item: IProduct): void {
    // console.log(item)
    item.showMore = true
  }
  tableRowClassName(row:any)   {
  // console.log(row.row.sortIndex ,"=====");
      if (row.row.sortIndex %2 ==1) {
        return 'success-row';
      } 
      //   if (row.rowIndex %2 !=0) {
      //   return 'success-row ';
      // } 
    };
     
  tableRowClassNameV2(row:any)   {
  // console.log(row.row.sortIndex ,"=====");
      if (row.rowIndex %2 ==1) {
        return 'success-row';
      } 
      //   if (row.rowIndex %2 !=0) {
      //   return 'success-row ';
      // } 
    };
    renderHeader(h:any, {column} :any) {
      let header = column.label.split(' ');
      return [h('p', [
        h('span', {style:{float:'left'}}, header[0]),
        h('span', {style:{'margin-left':'-75px'}}, header[1]),
        h('span', {style:{float:'right'}}, header[2]),
      ])];
    }
  
}
